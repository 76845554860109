angular.module('app.controllers').controller('NewsCtrl', ['$scope', '$rootScope', 'APIRead', 'Table', 'APIReadTable', '$cookies'].append(function(self, root, APIRead, Table, APIReadTable, $cookies) {
  var cookiesName, defaultParams, filterOffcanvas, filterOffcanvasEl, loadOffCount, ref, tableName;
  tableName = 'c.nov.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'test');
  cookiesName = 't.' + tableName;
  defaultParams = {
    categorie: "",
    brands: ""
  };
  self.tempParams = $cookies.getObject(cookiesName) || defaultParams;
  self.novelties = Table({
    name: tableName,
    type: "partial",
    resource: APIReadTable,
    cookies: true,
    params: {
      limit: 12,
      reverse: true,
      entry_type: "novelties"
    }
  });
  self.toggleOrder = function() {
    return self.novelties.params.reverse = !self.novelties.params.reverse;
  };
  self.page = 1;
  self.loadMore = function() {
    var params;
    if (self.page >= self.novelties.data.pages) {
      return;
    }
    self.page++;
    params = angular.copy(self.novelties.params);
    params.page = self.page;
    self.moreLoad = true;
    return APIRead.query(params, function(data) {
      self.more = data;
      self.novelties.data.display = self.novelties.data.display.concat(self.more);
      return self.moreLoad = false;
    });
  };
  self.applyFilter = function() {
    return angular.extend(self.novelties.params, self.tempParams);
  };
  self.resetFilter = function(offcanvas = false) {
    self.tempParams = angular.copy(defaultParams);
    self.applyFilter();
    if (typeof filterOffcanvasEl !== "undefined" && filterOffcanvasEl !== null) {
      if (offcanvas) {
        return filterOffcanvas.hide();
      }
    }
  };
  
  // Filter offcanvas

  APIRead.query({
    entry_type: 'novelties',
    stats: true
  }, function(data) {
    data = angular.copy(data);
    self.fbrands = data.filter(function(i) {
      return i.entry_type === 'brands';
    });
    return self.fcategorie = data.filter(function(i) {
      return i.entry_type === 'categorie';
    });
  });
  filterOffcanvasEl = document.getElementById('newsFilterOffcanvs');
  if (filterOffcanvasEl !== null) {
    filterOffcanvas = new bootstrap.Offcanvas(filterOffcanvasEl);
  }
  self.toggleFilter = function() {
    if (filterOffcanvas) {
      return filterOffcanvas.toggle();
    }
  };
  self.lvl = null;
  self.lvls = {
    brands: "Marken",
    categorie: "Kategorie"
  };
  self.isLvl1 = function() {
    if (self.lvl == null) {
      return true;
    }
    return false;
  };
  self.isLvl2 = function(key = null) {
    if (key == null) {
      return self.lvl;
    }
    return self.lvl === key;
  };
  self.toggleLvl = function(key = null) {
    if (self.isLvl2(key)) {
      key = null;
    }
    self.lvl = key;
    self.offCatFilter = '';
    return self.offBraFilter = '';
  };
  self.toggleFilter = function() {
    if (filterOffcanvas) {
      return filterOffcanvas.toggle();
    }
  };
  self.offChanged = function(key) {
    return self.offData.params[key] = self.offParams[key].join("|");
  };
  loadOffCount = function(offparams) {
    offparams.offcount = true;
    return self.offData = Table({
      name: tableName + ".off",
      type: "partial",
      resource: APIReadTable,
      cookies: true,
      params: offparams
    });
  };
  self.applyOff = function() {
    var offparams_2;
    offparams_2 = angular.copy(self.offData.params);
    delete offparams_2.offcount;
    self.novelties.params = offparams_2;
    return filterOffcanvas.hide();
  };
  if (filterOffcanvasEl !== null) {
    filterOffcanvasEl.addEventListener('show.bs.offcanvas', function() {
      var offparams_1, ref1, ref2;
      offparams_1 = angular.copy(self.novelties.params);
      self.offParams = {
        brands: ((ref1 = offparams_1.brands) != null ? ref1.split("|").filter(function(cat) {
          return cat;
        }) : void 0) || [],
        categorie: ((ref2 = offparams_1.categorie) != null ? ref2.split("|").filter(function(cat) {
          return cat || cat === 0;
        }).map(function(str) {
          return Number(str);
        }) : void 0) || []
      };
      return loadOffCount(offparams_1);
    });
    filterOffcanvasEl.addEventListener('hide.bs.offcanvas', function() {
      self.lvl = null;
      self.offCatFilter = '';
      return self.offBraFilter = '';
    });
  }
  return self.$watch('novelties.params', function(newObj, oldObj) {
    var as1, as2, ref1, ref2;
    as1 = (newObj != null ? (ref1 = newObj.brands) != null ? ref1.split("|").filter(function(cat) {
      return cat;
    }) : void 0 : void 0) || [];
    as2 = (newObj != null ? (ref2 = newObj.categorie) != null ? ref2.split("|").filter(function(cat) {
      return cat || cat === 0;
    }).map(function(str) {
      return Number(str);
    }) : void 0 : void 0) || [];
    return self.filterLength = as1.length + as2.length;
  }, true);
}));

angular.module('app.controllers').controller('NewsEditCtrl', ['$scope', '$rootScope', '$location', 'APIRead', 'UI'].append(function(self, root, $location, APIRead, UI) {
  self.id = parseInt(self.get('id'));
  APIRead.query({
    entry_type: 'novelties',
    entry_id: self.id
  }, function(data) {
    self.initData = data;
    self.data = data[0];
    return self.exhibitor = self.data.exhibitor_entry_data || null;
  }, function(response) {
    return UI.notify(false, response.data.error);
  });
  self.mainSlick = {
    arrows: true,
    fade: true,
    asNavFor: '.thumbnail-content-slider',
    infinity: true,
    responsive: [
      {
        breakpoint: 568,
        settings: {
          arrows: false
        }
      }
    ]
  };
  return self.thumbSlick = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinity: true,
    arrows: false,
    asNavFor: '.main-content-slider',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 568,
        settings: {
          centerMode: true,
          variableWidth: true,
          centerPadding: '10px'
        }
      }
    ]
  };
}));
