angular.module('app.controllers').controller 'NewsCtrl', [
  '$scope', '$rootScope', 'APIRead', 'Table', 'APIReadTable', '$cookies'
].append (self, root, APIRead, Table, APIReadTable, $cookies) ->

  tableName   = 'c.nov.' + (root.user?.shortcode || 'test')
  cookiesName = 't.' + tableName

  defaultParams = 
    categorie: ""
    brands: ""

  self.tempParams = $cookies.getObject(cookiesName) || defaultParams  


  self.novelties = Table
    name: tableName
    type: "partial"
    resource: APIReadTable
    cookies: true
    params: 
      limit: 12
      reverse: true
      entry_type: "novelties"


  self.toggleOrder = () ->
    self.novelties.params.reverse = !self.novelties.params.reverse

  self.page = 1
  self.loadMore = () ->
    return if self.page >= self.novelties.data.pages
    
    self.page++ 
    
    params = angular.copy(self.novelties.params)
    params.page = self.page

    self.moreLoad = true
    APIRead.query params, (data) ->
      self.more = data
      self.novelties.data.display = self.novelties.data.display.concat(self.more)
      self.moreLoad = false

  self.applyFilter = () ->
    angular.extend(self.novelties.params, self.tempParams)

  self.resetFilter = (offcanvas = false) ->
    self.tempParams = angular.copy(defaultParams)
    self.applyFilter()
    if filterOffcanvasEl?
      filterOffcanvas.hide() if offcanvas

  #
  # Filter offcanvas
  #

  APIRead.query {entry_type: 'novelties', stats: true}, (data) ->
    data = angular.copy(data)
    self.fbrands = data.filter (i) -> return i.entry_type == 'brands'
    self.fcategorie = data.filter (i) -> return i.entry_type == 'categorie'

  filterOffcanvasEl = document.getElementById('newsFilterOffcanvs')
  if filterOffcanvasEl != null
    filterOffcanvas = new bootstrap.Offcanvas(filterOffcanvasEl)

  self.toggleFilter = () ->
    filterOffcanvas.toggle() if filterOffcanvas
  
  self.lvl = null
  
  self.lvls = 
    brands: "Marken"
    categorie: "Kategorie"

  self.isLvl1 = () ->
    return true unless self.lvl?
    return false

  self.isLvl2 = (key = null) ->
    return self.lvl unless key?
    return self.lvl == key

  self.toggleLvl = (key = null) ->
    key = null if self.isLvl2(key)
    self.lvl = key

    self.offCatFilter = ''
    self.offBraFilter = ''

  self.toggleFilter = () ->
    filterOffcanvas.toggle() if filterOffcanvas

  self.offChanged = (key) ->
    self.offData.params[key] = self.offParams[key].join("|")

  loadOffCount = (offparams) ->
    offparams.offcount = true

    self.offData = Table
      name: tableName + ".off"
      type: "partial"
      resource: APIReadTable    
      cookies: true
      params: offparams

  self.applyOff = () ->
    offparams_2 = angular.copy(self.offData.params)
    delete(offparams_2.offcount)
    self.novelties.params = offparams_2
    filterOffcanvas.hide()

  if filterOffcanvasEl != null
    filterOffcanvasEl.addEventListener('show.bs.offcanvas', () ->
      offparams_1 = angular.copy(self.novelties.params)

      self.offParams = 
        brands:      offparams_1.brands?.split("|").filter((cat) -> return cat) || []
        categorie:   offparams_1.categorie?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []

      loadOffCount(offparams_1)
    )

    filterOffcanvasEl.addEventListener('hide.bs.offcanvas', () ->
      self.lvl = null
      self.offCatFilter = ''
      self.offBraFilter = ''
    )

  self.$watch 'novelties.params', (newObj, oldObj) ->
    as1 = newObj?.brands?.split("|").filter((cat) -> return cat) || []
    as2 = newObj?.categorie?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []

    self.filterLength = as1.length + as2.length
  , true

angular.module('app.controllers').controller 'NewsEditCtrl', [
  '$scope', '$rootScope', '$location', 'APIRead', 'UI'
].append (self, root, $location, APIRead, UI) ->

  self.id = parseInt(self.get('id'))
  
  APIRead.query {entry_type: 'novelties', entry_id: self.id}, (data) ->
    self.initData = data
    self.data = data[0]
    self.exhibitor = self.data.exhibitor_entry_data || null
  , (response) ->
    UI.notify false, response.data.error

  
  self.mainSlick =
    arrows: true
    fade: true
    asNavFor: '.thumbnail-content-slider'
    infinity: true
    responsive: [
      {
        breakpoint: 568
        settings:
          arrows: false
      
      }
    ]

  self.thumbSlick =
    slidesToShow: 4
    slidesToScroll: 1
    infinity: true
    arrows: false
    asNavFor: '.main-content-slider'
    focusOnSelect: true
    responsive: [
      {
        breakpoint: 568
        settings: 
          centerMode: true
          variableWidth: true
          centerPadding: '10px'
      }
    ]